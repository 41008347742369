import React from "react"
import { Link } from "gatsby"
import Img from "gatsby-image"
import { motion } from "framer-motion"

import Button from "../components/Button"
import DemoStyles from "./Demo.module.scss"

export default ({ children }) => {
    return (
        <div className={`absolute`} style={{width: 320+'px'}}>
            <div className={`absolute ${DemoStyles.iphone}`}>
                <div className="mx-4" style={{
                        paddingTop: 0.3+'rem',
                    }}>
                    <motion.div
                        animate={{ opacity: 1 }}
                        transition={{ ease: "easeIn", duration: 1 }}
                        className="bg-gray-100 overflow-hidden" 
                        style={{
                            borderRadius: 16+'px',
                            borderBottomLeftRadius: 24+'px',
                            borderBottomRightRadius: 24+'px',
                            padding: 16+'px',
                            marginTop: 16+'px',
                            maxHeight: 699+'px'
                        }}>
                        {children}
                    </motion.div>
                </div>
            </div>
        </div>
    )
}
