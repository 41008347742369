import React from "react"
export default ({ items }) => {
    return (
        <div className="w-full" style={{
            paddingLeft:8+'px',
            paddingRight:8+'px'
        }}>
            <ul>
                {items.map((item) => (
                    <li className="flex flex-row justify-between pt-2 pb-2 border-b border-gray-400">
                        <div className="font-bold">
                            {item.title}
                        </div>
                        <div>
                            {item.content}
                        </div>
                    </li>
                ))}
            </ul>
        </div>
    )
}
